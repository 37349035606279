const AppFooter = ({children}) => {
    return(
        <>
            <footer style={{
                minHeight:'100px',
                backgroundColor:'lightgrey',
                padding:'15px',
                alignItems:'centre',
                boxShadow:'0 50vh 0 50vh lightgrey'}}>
                <div className="container">
                    <div className="row justify-content-between">
                        {
                            children
                        }
                    </div>
                </div>
            </footer>
        </>
    );
}

export default AppFooter;