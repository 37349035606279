import { Link } from "react-router-dom";

const CustomLink = ({to, display}) =>{
    return(
        <Link to={to} className='nav-link' onClick={() => {
            window.scrollTo(0,0);
        }} >
            {display}
        </Link>
    )
}

export default CustomLink;