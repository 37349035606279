import React from 'react';
import { Container, Nav, Navbar } from "react-bootstrap";
import CompanyInfo from "../../components/companyInfo";
import OpeningHours from "../../components/openingHours";
import Legal from "../../components/legal";
import AppFooter from "../footer"
import { Link, Outlet } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import CustomLink from '../../components/customLink';
import Socials from '../../components/socials';
import { useState } from 'react';

const AppLayout = () =>{
    const [expanded, setExpanded] = useState(false);
    
    return(
        <>
            <header>
                <Navbar bg='light' expand='lg' fixed='top' expanded={expanded}>
                    <Container>
                        <Navbar.Brand>
                            <CustomLink to="/" display="RAN Upholstery" className='nav-link'></CustomLink>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls='basic-navbar-nav' onClick={() => setExpanded(expanded ? false : "expanded")}></Navbar.Toggle>
                        <Navbar.Collapse>
                            <Nav>
                                <Link className='nav-link' to='/' onClick={() => {setExpanded(false);}}>Home</Link>
                                <Link className='nav-link' to='services' onClick={() => {setExpanded(false)}}>Services</Link>
                                <Link className='nav-link' to='gallery' onClick={() => {setExpanded(false)}}>Gallery</Link>
                                <Link className='nav-link' to='about' onClick={() => {setExpanded(false)}}>About</Link>
                                <Link className='nav-link' to='contact' onClick={() => {setExpanded(false)}}>Contact</Link>
                                <Link className='nav-link' to='tel:+447711025474' rel="external nofollow noopener noreferrer" target='_blank' onClick={() => {setExpanded(false)}}>Tel: +447711025474</Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
            <main style={{marginTop:'90px'}}>
                <Outlet />
            </main>
            <CookieConsent
                location='bottom'
                buttonText="Accept"
                cookieName="cookie-consent"
                style={{background:"#fff", color:"#000", borderTop:"1px solid #000"}}
                buttonStyle={{color:"#fff", background:"#000"}}>
                This website uses cookies to enhance the user experience.
            </CookieConsent>
            <AppFooter>
                <CompanyInfo name='RAN Upholstery' />
                <OpeningHours />
                <Legal />
                <Socials />
            </AppFooter>
        </>
    );
}

export default AppLayout;