import DocumentMeta from "react-document-meta";
import IndexPage from "../../scenes/index"

const Page = () =>{
    const meta = {
        title: 'RAN Upholstery | Master Upholsterer\'s in Norwich and Lowestoft',
        description: 'RAN Upholstery Master Upholsterers in Norwich & Lowestoft',
        canonical: 'https://ranupholstery.co.uk/',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'upholstery,repairs,norwich,lowestoft,suffolk,near,me,experienced,antiques,dining,sofa'
          }
        }
    }
    return (
        <>
            <DocumentMeta {...meta}>
                <IndexPage />
            </DocumentMeta>
        </>
    )
}

export default Page;