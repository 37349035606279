import facebook from '../../images/iconography/facebook.png'
import instagram from '../../images/iconography/instagram.png'
const Socials = () => {
    return(
        <>
            <div style={{display:'flex', justifyContent:'center', marginBottom:'1em'}}>
                <a href='https://www.facebook.com/RANUpholstery/'
                    style={{margin:'0 1.5em'}}
                    rel="external nofollow noopener noreferrer" target="_blank">
                    <img src={facebook} style={{width:'2em', height:'2em'}} alt='facebook social' />
                </a>
                <a href='https://www.instagram.com/ran_upholstery/'
                    style={{margin:'0 1.5em'}}
                    rel="external nofollow noopener noreferrer" target="_blank">
                    <img src={instagram} style={{width:'2em', height:'2em'}} alt='instagram social' />
                </a>
            </div>
        </>
    )
}

export default Socials;