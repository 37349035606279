import CustomLink from "../customLink";

const Legal = () => {
    return(
        <>
            <div className="col col-lg-2 mb-3">
                <h3>Legal</h3>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <CustomLink to='privacy' display='Privacy Policy'></CustomLink>
                    </li>
                    <li className="nav-item">
                        <CustomLink to='terms' display='Terms of Service'></CustomLink>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Legal;