import DocumentMeta from "react-document-meta";
import ContactPage from "../../scenes/contact"

const Page = () =>{
    const meta = {
        title: 'RAN Upholstery | Send us a message',
        description: 'RAN Upholstery Contact Page',
        canonical: 'https://ranupholstery.co.uk/contact',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'upholstery,repairs,norwich,lowestoft,suffolk,near,me,experienced,antiques,dining,sofa,contact,phone,location'
          }
        }
    }
    return (
        <>
            <DocumentMeta {...meta}>
                    <ContactPage />
            </DocumentMeta>
        </>
    )
}

export default Page;