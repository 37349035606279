const OpeningHours = () => {
    return (
        <>
            <div className="col-md-auto mb-3">
                <h3>Opening Hours:</h3>
                <ul style={{listStyleType:'none', padding:'0'}}>
                    <li>Monday - Friday 07:00 - 17:00</li>
                    <li>Enquiries are welcomed any time of day</li>
                </ul>
            </div>
        </>
    )
}

export default OpeningHours;