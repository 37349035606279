import { Link } from "react-router-dom";
import PageHeader from "../../components/pageHeader";
import AppBody from "../../layouts/body";
import { Button } from "react-bootstrap";

const ServicesPage = () => {
    return(
        <>
            <PageHeader title='Our Services' />
            <AppBody>
                <div className="container mb-3">
                    <section id='antiques+leathers' className="mb-5 p-5" style={{boxShadow:'3px 3px 3px 3px'}}>
                        <h2 style={{fontSize:'2em'}}>Antiques and leathers</h2>
                        <p className="services">If you are in the Norwich and Lowestoft area with antique treasures or leather furniture pieces in need of repair and reupholstering, we are here to assist you. Our expertise lies in the meticulous restoration and rejuvenation of antiques and fine leather furniture, ensuring that your beloved pieces retain their beauty and functionality for years to come.</p>
                        <p className="services">When it comes to antiques, we understand the value of preserving their historical significance and unique craftsmanship. We possess extensive knowledge in antique restoration techniques, carefully addressing issues commonly found on antique pieces. With the utmost care and precision, we breathe new life into these heirloom pieces while maintaining their authenticity and charm.</p>
                        <p className="services">In the realm of leather upholstery, we specialize in reviving worn-out or damaged furniture to its former glory. From antique leather armchairs to contemporary leather sofas, we skillfully repair and reupholster these pieces using high-quality materials and techniques. We pay meticulous attention to every detail, ensuring that the new upholstery complements the original design and enhances the overall aesthetic.</p>
                        <p className="services">Our services are designed to cater to both residential and commercial clients in the Norwich and Lowestoft area. Whether you have a cherished antique that needs delicate restoration or a leather furniture piece in need of a fresh look, we are committed to delivering exceptional results. Contact us today to discuss your repair and reupholstery needs, and let us help you breathe new life into your treasured antiques and leather furniture.</p>
                        <Link to='/contact'><Button>Contact us</Button></Link>
                    </section>
                    <section id='upholstery+reupholstery' className="mb-5 p-5" style={{boxShadow:'3px 3px 3px 3px'}}>
                        <h2 style={{fontSize:'2em'}}>Upholstery & Reupholstery</h2>
                        <p className="services">We are dedicated to providing top-quality craftsmanship and exception customer service for all your upholstery needs within the Norwich and Lowestoft area.</p>
                        <p className="services">We possesses over 30 years of experience in the industry, specializing in both residential and commercial projects. Whether you have a worn-out sofa, dining chairs in need of a fresh look, or antique furniture that requires delicate restoration, we have the expertise to deliver outstanding results.</p>
                        <p className="services">When it comes to upholstery, we offer a wide range of services tailored to your specific requirements. From fabric selection to design consultation, our knowledgeable team will guide you through the process, ensuring that your vision is brought to life. We work with a vast selection of high-quality fabrics, allowing you to choose the perfect material that suits your style and preferences.</p>
                        <p className="services">For those seeking reupholstery services, we understand the sentimental and monetary value that your furniture holds. We take great care in removing the old upholstery, preserving the integrity of the piece, and meticulously applying new fabric to transform your furniture into a beautiful, refreshed masterpiece.</p>
                        <p className="services">Whether you're a homeowner looking to update your interiors or a business owner aiming to create a professional and inviting atmosphere, RAN Upholstery in the Norwich and Lowestoft area provide services that are tailored to meet your needs. We pride ourselves on our attention to detail, exceptional craftsmanship, and commitment to customer satisfaction. Contact us today to discuss your upholstery project and let us help you breathe new life into your furniture.</p>
                        <Link to='/contact'><Button>Contact us</Button></Link>
                    </section>
                    <section id='commercial+domestic+marine' className="mb-5 p-5" style={{boxShadow:'3px 3px 3px 3px'}}>
                        <h2 style={{fontSize:'2em'}}>Commercial, Domestic & Marine</h2>
                        <p className="services">We are proud to offer a comprehensive range of upholstery and reupholstery services catering to commercial, domestic, and marine clients. Whether you're a business owner, homeowner, or maritime enthusiast, our skilled team is well-equipped to handle all your upholstery needs, ensuring exceptional quality and customer satisfaction.</p>
                        <p className="services">For our commercial clients, we understand the importance of creating a professional and inviting atmosphere. We specialize in upholstery services for restaurants, hotels, offices, and other commercial establishments. Our expert team can revitalize your seating arrangements, booths, and lobby furniture, providing a fresh and appealing look that will leave a lasting impression on your customers and clients.</p>
                        <p className="services">When it comes to domestic upholstery, we offer a wide range of services to enhance the beauty and comfort of your home. From reupholstering worn-out sofas and chairs to custom designing headboards and ottomans, we have the expertise to transform your living spaces. Our skilled craftsmen work closely with you to select the perfect fabrics, patterns, and finishes that complement your interior design vision, creating a personalized and inviting ambiance.</p>
                        <p className="services">In the realm of marine upholstery, we cater to boat owners and enthusiasts who seek to enhance their vessels' aesthetics and functionality. We understand the unique challenges posed by marine environments and utilize specialized marine-grade materials that are resistant to UV rays, water, and mildew. Whether you need to reupholster boat seats, cushions, or helm chairs, our team ensures durability and style, allowing you to enjoy the open water in comfort and luxury.</p>
                        <p className="services">Regardless of the sector we serve, RAN Upholstery are backed by years of experience, attention to detail, and a commitment to excellence. We strive to deliver exceptional results that exceed your expectations, transforming your commercial spaces, homes, and marine vessels into spaces of beauty and comfort. If you're located in the Norwich and Lowestoft area, contact us today to discuss your upholstery project and let us bring new life to your surroundings.</p>
                        <Link to='/contact'><Button>Contact us</Button></Link>
                    </section>
                    <section id='repairs' className="mb-5 p-5" style={{boxShadow:'3px 3px 3px 3px'}}>
                        <h2 style={{fontSize:'2em'}}>Repairs</h2>
                        <p className="services">
                        In the Norwich and Lowestoft area, we take pride in offering top-notch upholstery repair services to meet all your needs. If your furniture has seen better days or has suffered from wear and tear, our skilled team of experts is here to help restore its beauty and functionality.</p>
                        <p className="services">Our process begins with a thorough assessment of your upholstery, identifying the specific issues that need repair. We work closely with you to understand your desired outcome, ensuring that our repairs align with your vision. Our skilled artisans then utilize their expertise and a wide range of professional tools and materials to carefully execute the repairs, paying attention to every detail along the way.</p>
                        <p className="services">We pride ourselves on our ability to work with various types of furniture, including sofas, chairs, ottomans, and more. Whether your upholstery is made from fabric, leather, or a combination of materials, we have the knowledge and skills to handle the repairs with precision and care.</p>
                        <p className="services">At RAN Upholstery in the Norwich and Lowestoft area, we are dedicated to providing exceptional customer service and delivering results that exceed your expectations. With our attention to detail and commitment to quality, you can trust us to breathe new life into your beloved furniture, prolonging its lifespan and ensuring it continues to bring comfort and beauty to your space for years to come. Contact us today to discuss your upholstery repair needs and let us help you restore your furniture to its former glory.</p>
                        <Link to='/contact'><Button>Contact us</Button></Link>
                    </section>
                    <section id='interiors' className="mb-5 p-5" style={{boxShadow:'3px 3px 3px 3px'}}>
                        <h2 style={{fontSize:'2em'}}>Interiors</h2>
                        <p className="services">
                        RAN Upholstery is a reputable company in providing top-notch interior upholstery services in various areas, including Norwich and Lowestoft. We take great pride in our craftsmanship and attention to detail, offering a range of upholstery services to transform and revitalize your living spaces.</p>
                        <p className="services">Whether you're looking to refresh your home's interiors or seeking to add a touch of elegance to your business establishment, our skilled team at RAN Upholstery has you covered. We specialize in a wide array of interior upholstery services, including furniture reupholstery, custom upholstery, and repair. Our goal is to bring your vision to life while enhancing the comfort, style, and durability of your furniture.</p>
                        <p className="services">Our interior upholstery services extend beyond residential spaces to commercial settings as well. Whether you own a restaurant, hotel, or office, we can transform your seating arrangements, booths, and other furniture to create a professional and inviting atmosphere for your customers and clients. We understand the importance of quality and durability in commercial settings and ensure that our upholstery work withstands heavy use while maintaining its elegance and comfort.</p>
                        <p className="services">With RAN Upholstery, you can expect exceptional customer service, meticulous craftsmanship, and timely project completion. We prioritize customer satisfaction and strive to exceed your expectations in every aspect of our work. Contact us today to discuss your interior upholstery needs, and let RAN Upholstery elevate the aesthetic appeal and comfort of your spaces with our impeccable upholstery services.</p>
                        <Link to='/contact'><Button>Contact us</Button></Link>
                    </section>
                </div>
            </AppBody>
        </>
    )
}

export default ServicesPage;