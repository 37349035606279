const AppBody = ({children}) =>{
    return(

        <div className="container" style={{minHeight:'100vh', marginTop:'1.5em', maxWidth:'100%'}}>
            {
                children
            }
        </div>
    )
}

export default AppBody;