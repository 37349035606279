import DocumentMeta from "react-document-meta";
import AboutPage from "../../scenes/about"

const Page = () =>{
    const meta = {
        title: 'RAN Upholstery | What we\'re about',
        description: 'RAN Upholstery About Page',
        canonical: 'https://ranupholstery.co.uk/about',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'upholstery,repairs,norwich,lowestoft,suffolk,near,me,experienced,antiques,dining,sofa,about'
          }
        }
    }
    return (
        <>
            <DocumentMeta {...meta}>
                <AboutPage />
            </DocumentMeta>
        </>
    )
}

export default Page;