import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ContactDetails from "../../components/contactDetails";
import ContactMap from "../../components/contactMap";
import PageHeader from "../../components/pageHeader";
import AppBody from "../../layouts/body";

const client = new QueryClient();

const ContactPage = () =>{
    return(
        <>
            <PageHeader title="Contact"></PageHeader>
            <AppBody>
                <QueryClientProvider client={client}>
                    <ContactDetails />
                </QueryClientProvider>
                <ContactMap></ContactMap>
            </AppBody>
        </>
    )
}

export default ContactPage;