import PageHeader from "../../components/pageHeader";
import AppBody from "../../layouts/body";
import woody from '../../images/about/woody.jpg';
import rob from '../../images/about/rob.jpg'
import nat from '../../images/about/nat.jpeg'
const AboutPage = () =>{
    return(
        <>
            <PageHeader title="About"></PageHeader>
            <AppBody>
            <div className="container mb-3">
                <div className="row">
                	<div className="col-md-6">
                        <div>
                    	    <h1 className="about-header">Who we are and<br />what we do</h1>
                            <p className="lead">Norwich and Lowestofts master upholsterers with <br /> over 30 years experience</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                    	<p>RAN Upholstery is a family run business in the Lowestoft area and has been established since 2020. With over 30 years of experience in the industry, we specialise in both traditional and modern upholstery techniques to provide you with the highest quality results. We have extensive experience, ranging from reupholstering and repairing antique's and commercial pieces. We also cover domestic, leathers, nautical and interiors.</p>
                    </div>
                </div>
            </div>
            <div className="container">
            <div className="row mb-3">
                <div className="text-center">
                    <h1 className="about-header">Our Team</h1>
                    <p>We are committed to work with our customers to ensure that all of their requirements are met. <br />
                       It is our belief that building this rapport with our customers will leave them totally satisfied every time!</p>
                    <div className="border"></div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 team-box">
                    <div className="team-img thumbnail">
                    <img src={rob} style={{maxHeight:"20em", margin:"0 auto", display:"block"}} alt='rob profile' />
                    <div style={{padding:"1em"}}>    
                        <h3 className="about-header">Rob</h3>
                        <div className="border-team"></div>
                        <p>I'm Rob and I've been doing this for over 30 years. I finally went on my own after the pandemic hit as I thought if I didn't do it then, I never would. Aside from working and running the business, I am an avid golf player and you can often find me playing on my days off.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-4 team-box">
                <div className="team-img thumbnail">
                    <img src={nat} style={{maxHeight:"20em", margin:"0 auto", display:"block"}} alt='nat profile' />
                    <div style={{padding:"1em"}}>    
                        <h3 className="about-header">Nat</h3>
                        <div className="border-team"></div>
                        <p>With over 20 years experience working in sales within the upholstery business, I decided to take the challenge in helping Rob grow and manage RAN Upholstery. When I'm not in the workshop, I'm usually spending quality time at home or on long walks with Woody!</p>
                    </div>
                </div>
            </div>
                <div className="col-md-4 team-box">
                    <div className="team-img thumbnail">
                    <img src={woody} style={{maxHeight:"20em", margin:"0 auto", display:"block"}} alt='woody profile' />
                        <div style={{padding:"1em"}}>    
                            <h3 className="about-header">Woody</h3>
                            <div className="border-team"></div>
                            <p>I'm Woody and I'm the workshop dog. You can often find me helping out by the sewing machines or keeping Rob & Nat warm by wrapping myself around their necks for cuddles.</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </AppBody>
        </>
    )
}

export default AboutPage;