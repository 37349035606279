import { Toast, ToastContainer } from 'react-bootstrap';

const Notification = ({header, subtext, type, show, close}) =>{
    return(
        <>
            <ToastContainer position='bottom-center'>
                <Toast className='alert' onClose={close} show={show} delay={3000} autohide
                bg={type}>
                    <h4 className='alert-heading'>
                        <strong>{header}</strong>
                    </h4>
                    <Toast.Body>{subtext}</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
}

export default Notification;