import './bootstrap.min.css';
import AboutPage from './pages/about'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AppLayout from './layouts/layout';
import GalleryPage from './pages/gallery';
import ContactPage from './pages/contact';
import TermsPage from './pages/terms';
import IndexPage from './pages/index';
import PrivacyPage from './pages/privacy';
import ServicesPage from './pages/services';

const router = createBrowserRouter([
  {
    path:"/",
    element: <AppLayout />,
    children:[{
      path:"/",
      element: <IndexPage />
    },{
      path:"/about",
      element: <AboutPage />
    },{
      path:"/gallery",
      element: <GalleryPage />
    },{
      path:"/contact",
      element: <ContactPage />
    },{
      path:"/terms",
      element: <TermsPage />
    },{
      path:"/privacy",
      element: <PrivacyPage />
    },{
      path:"/services",
      element: <ServicesPage />
    }]
  }
]);

function App() {
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
}

export default App;
