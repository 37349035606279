import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import React, {useState} from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Notification from '../notification';
import ReCAPTCHA from 'react-google-recaptcha';

const SendEmail = () => {
    const url = 'https://lexicologist.azurewebsites.net/api/v1.0/emaildispatcher';
    // const url = 'https://localhost:7037/api/v1.0/emaildispatcher';

    return useMutation((formPayload) => {
        return axios.post(url,
        formPayload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    });
}

const ContactForm = () =>{
    const [showSuccess, setSuccess] = useState(false);
    const [showFailure, setFailure] = useState(false);
    const [captchaIsDone, setCaptchaDone] = useState(false);
    const [token, setToken] = useState('');
    const key = '6LeLb8YlAAAAAJ5-5GNZqmHL9ws0tORbcOqDiuUj';
  
    function onChange(value){
        setToken(value);
        setCaptchaDone(true);
    }

    const { mutate } = SendEmail();
    const validationSchema = object({
        fullName: string("Enter your full name")
        .min(3)
        .required("Full Name is required"),
        email: string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
        phoneNumber: string().matches(/^\+?[0-9]\d{1,20}$/)
        .required("Phone number is required")
        .min(11),
        message: string()
        .required("Message is required")
      });

    const formik = useFormik({
        initialValues:{
            fullName: "",
            email: "",
            phoneNumber: "",
            message: ""
        },
        onSubmit: (values) =>{
            const data = {
                subject: `New Message From: ${values.fullName}`,
                body: `Email: ${values.email} Phone number: ${values.phoneNumber} Message: ${values.message}`,
                sendTo: `${values.email}`,
                token: `${token}`
            };
            mutate(data,{
                onSuccess: () => {
                    formik.resetForm();
                    setSuccess(true);
                },
                onError: (response) => {
                    formik.setSubmitting(false);
                    setFailure(true);
                }
            });
        },
        validationSchema: validationSchema,
    });

    return(
        <>
            <Form>
                <Form.Group className="mb-3" controlId="fullName">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control placeholder="Enter name" name='fullName'
                    value={formik.values.fullName} onChange={formik.handleChange}
                    isInvalid={formik.touched.fullName && Boolean(formik.errors.fullName)}
                    isValid={formik.touched.fullName && !Boolean(formik.errors.fullName)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email"
                    value={formik.values.email} onChange={formik.handleChange}
                    isInvalid={formik.touched.email && Boolean(formik.errors.email)}
                    isValid={formik.touched.email && !Boolean(formik.errors.email)} />
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="phoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control placeholder="Phone number"
                    value={formik.values.phoneNumber} onChange={formik.handleChange}
                    type='text' inputMode='numeric'
                    isInvalid={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    isValid={formik.touched.phoneNumber && !Boolean(formik.errors.phoneNumber)} />
                    <Form.Text className="text-muted">
                        We'll never share your phone number with anyone else.
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="message">
                    <Form.Label>Message</Form.Label>
                    <textarea placeholder="Message" className={`form-control 
                    ${formik.touched.message && Boolean(formik.errors.message) ? "is-invalid" : ""}
                    ${formik.touched.message && !Boolean(formik.errors.message) ? "is-valid" : ""}`} 
                    rows="7" style={{resize:'none'}}
                    value={formik.values.message} onChange={formik.handleChange} id="message" />
                </Form.Group>
                <ReCAPTCHA
                    sitekey={key}
                    onChange={onChange} />
                <hr />
                <Button variant="primary" disabled={formik.isSubmitting || !captchaIsDone} onClick={formik.handleSubmit} 
                type='submit'>
                    Submit
                </Button>
            </Form>
            <Notification
            header="Failed!"
             subtext="Unfortunately there was an error sending your message. 
                        Please get in contact with us via our phone number. 
                        We have been notified of this error and are looking into the issue."
            show={showFailure}
            close={() => setFailure(false)}
            type="danger" />
            <Notification
            header="Success!"
             subtext="Your message was successfully sent! Please expect to hear back from us within 48 hours."
            show={showSuccess}
            close={() => setSuccess(false)}
            type="success" />
        </>
    )
}

export default ContactForm;