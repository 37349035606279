import DocumentMeta from "react-document-meta";
import GalleryPage from "../../scenes/gallery"

const Page = () =>{
    const meta = {
        title: 'RAN Upholstery | Our Work',
        description: 'RAN Upholstery Gallery',
        canonical: 'https://ranupholstery.co.uk/about',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'upholstery,repairs,norwich,lowestoft,suffolk,near,me,experienced,antiques,dining,sofa,work,gallery'
          }
        }
    }
    return (
        <>
            <DocumentMeta {...meta}>
                <GalleryPage />
            </DocumentMeta>
        </>
    )
}

export default Page;