import DocumentMeta from "react-document-meta";
import TermsPage from "../../scenes/terms"

const Page = () =>{
    const meta = {
        title: 'RAN Upholstery | Terms of Service',
        description: 'RAN Upholstery Terms of Service',
        canonical: 'https://ranupholstery.co.uk/about',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'ran,upholstery,norwich,lowestoft,terms,service'
          }
        }
    }
    return (
        <>
            <DocumentMeta {...meta}>
                <TermsPage />
            </DocumentMeta>
        </>
    )
}

export default Page;