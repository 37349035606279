import PageHeader from "../../components/pageHeader";
import AppBody from "../../layouts/body";

const GalleryPage = () =>{
    return(
        <>
            <PageHeader title="Gallery"></PageHeader>
            <AppBody>
            <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
            <div class="elfsight-app-65ed9ae7-c648-4a69-b811-25fcd10de4dc"></div>
            </AppBody>
        </>
    )
}

export default GalleryPage;