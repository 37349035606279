import { Card } from "react-bootstrap";
import ContactForm from "../contactForm";

const ContactDetails = () =>{
    return (
        <>
            <Card className="mb-3 contact" style={{margin:'0 auto',justifyContent:'center'}}>
                <Card.Body>
                    <h4 className="text-center">Send us a message!</h4>
                    <p className="text-center">We would love to hear from you! 
                    <br />Please note that our general response time to enquiries of any kind is within 48 hours</p>
                    <ContactForm></ContactForm>
                </Card.Body>
            </Card>
        </>
    )
}

export default ContactDetails;