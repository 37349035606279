import { Card } from "react-bootstrap";

const ContactMap = () =>{
    return(
        <>
            <div className="row mb-3">
                <div className="col mb-3">
                    <Card style={{height:"100%"}}>
                        <Card.Body>
                            <h4 className="text-center mb-5">Our Details</h4>
                            <div className="row mb-5 p-1">
                                <div className="col">
                                    <h6>Phone</h6>
                                </div>
                                <div className="col">
                                    <a href="tel:+447711025474"
                                     rel="external nofollow noopener noreferrer"
                                     target="_blank">+447711025474</a>
                                </div>
                            </div>
                            <div className="row mb-5 p-1">
                                <div className="col">
                                    <h6>Address</h6>
                                </div>
                                <div className="col">
                                    <a target="_blank" 
                                    rel="external nofollow noopener noreferrer"
                                    href="https://goo.gl/maps/CPiJJaeuQem5jbHk7">Unit 10, North East Suffolk Business Centre, Lowestoft, NR33 7NQ</a>
                                </div>
                            </div>
                            <div className="row mb-5 p-1">
                                <div className="col">
                                    <h6>Email</h6>
                                </div>
                                <div className="col">
                                    <a href="mailto:support@ranupholstery.co.uk"
                                    rel="external nofollow noopener noreferrer"
                                     target="_blank">support@ranupholstery.co.uk</a>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col mb-2">
                    <iframe name="ran map"
                        title="ranmap"
                        className="card"
                        style={{minHeight:"500px", minWidth:"100%"}}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2432.001955229295!2d1.7123724159968945!3d52.442879349693875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d9e7509fcfd935%3A0x2798dfdeac8beeaa!2sRAN%20Upholstery!5e0!3m2!1sen!2suk!4v1674323576310!5m2!1sen!2suk"
                        loading="lazy"></iframe>
                </div>
            </div>
        </>
    );
}

export default ContactMap;