import DocumentMeta from "react-document-meta";
import PrivacyPage from "../../scenes/privacy"

const Page = () =>{
    const meta = {
        title: 'RAN Upholstery | Privacy Policy',
        description: 'RAN Upholstery Privacy Policy',
        canonical: 'https://ranupholstery.co.uk/about',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'ran,upholstery,norwich,lowestoft,privacy,policy'
          }
        }
    }
    return (
        <>
            <DocumentMeta {...meta}>
                <PrivacyPage />
            </DocumentMeta>
        </>
    )
}

export default Page;