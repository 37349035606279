import React from 'react'
import FabricDisplayLarge from '../../images/index/fabric_display_large.jpg'
import FabricDisplayMedium from '../../images/index/fabric_display_medium.jpg'
import FabricDisplaySmall from '../../images/index/fabric_display_small.jpg'
import ChairDisplayLarge from '../../images/index/upholstery_large.jpg'
import ChairDisplayMedium from '../../images/index/upholstery_medium.jpg'
import ChairDisplaySmall from '../../images/index/upholstery_small.jpg'
import { Button, Card } from 'react-bootstrap'
import { HashLink as Link } from 'react-router-hash-link'
const IndexPage = () =>{
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -95;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'})
    }
    return(
        <>
            <section id="introduction">
                <div style={{position:'relative'}}>
                    <div style={{position:'absolute', top:'15%', left:'50%', 
                    transform:'translate(-50%,-50%)',color:'var(--bs-white)', padding:'1.5em', backgroundColor:'hsla(0,0%,50%,0.5', width:'90%'}}>
                        <h1 style={{fontSize:'2.5rem', color:"white"}}>RAN Upholstery</h1>
                        <p style={{fontSize:'2rem'}}>We offer full upholstery & reupholstery services in the Norwich, Lowestoft and surrounding areas</p>
                        <br />
                        <div className='text-center'>
                            <Link to='contact'>
                                <Button variant='primary'>Contact us for a quote today!</Button>
                            </Link>
                        </div>
                    </div>
                    <picture style={{backgroundSize:'cover', overflow:'hidden',width:'100%'}}>
                        <source srcSet={FabricDisplaySmall} media="(max-width: 600px)"></source>
                        <source srcSet={FabricDisplayMedium} media="(max-width: 900px)"></source>
                        <source srcSet={FabricDisplayLarge} media="(min-width: 901px)"></source>
                        <img src={FabricDisplayLarge} style={{width:'100%', height:'95vh'}}  alt='fabric display' />
                    </picture>
                    <div className="container mt-5 mb-5" style={{minHeight:'200px'}}>
                        <h2 className='text-center' style={{fontSize:'2.5rem'}}>Norwich and Lowestoft's Master Upholsterers</h2>
                        <div className='text-center mb-3' style={{backgroundColor:'hsla(0,0%,90%,0.5',padding:'2em',borderRadius:'10px'}}>
                            <i style={{fontSize:'1.75rem',fontWeight:'bold'}}>"At RAN Upholstery we take the utmost pride and attention to detail in our work. Delivered to the highest level of professionalism, we work with our customers to deliver their dream piece of furniture. Just check out our reviews!" - Rob</i>
                        </div>
                        <div style={{minHeight:'300px'}}>
                            <div className="elfsight-app-aac171c1-0e98-4c4c-9354-21ca50a3b2bb"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="services">
                <div style={{position:'relative'}}>
                    <div style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)',color:'var(--bs-white)', padding:'1.5em', backgroundColor:'hsla(0,0%,50%,0.5'}}>
                        <h2 style={{fontSize:'2.5rem', color:"white"}}>Services we Offer</h2>
                    </div>
                    <picture style={{backgroundSize:'cover', overflow:'hidden',width:'100%'}}>
                        <source srcSet={ChairDisplaySmall} media="(max-width: 600px)"></source>
                        <source srcSet={ChairDisplayMedium} media="(max-width: 900px)"></source>
                        <source srcSet={ChairDisplayLarge} media="(min-width: 901px)"></source>
                        <img src={ChairDisplayLarge} style={{width:'100%', height:'90vh'}}  alt='chair upholstered' />
                    </picture>
                </div>
                <div className="container" style={{minHeight:'200px'}}>
                    <div className='row p-3'>
                        <Card className='p-2 mt-5 mb-5'>
                            <Card.Title><h3 style={{fontSize:'2em'}}>Antiques & Leathers</h3></Card.Title>
                            <Card.Body>
                                <div className='row' style={{justifyContent:'space-between', display:'flex', flexDirection:'column'}}>
                                    <div className='col' style={{fontSize:'1.25em'}}>
                                        We specialise in providing exceptional antique and leather upholstery and repair services. Trust us to breathe new life into your cherished heirlooms and beloved leather pieces with our dedicated crafstmapship and attention to detail.
                                    </div>
                                    <div className='col mt-3'>
                                        <Link to='services#antiques+leathers' scroll={el => scrollWithOffset(el)}>
                                            <Button>Find out more</Button>
                                        </Link>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='p-2 mb-5'>
                            <Card.Title><h3 style={{fontSize:'2em'}}>Upholstery & Reupholstery</h3></Card.Title>
                            <Card.Body>
                                <div className='row' style={{justifyContent:'space-between', display:'flex', flexDirection:'column'}}>
                                    <div className='col' style={{fontSize:'1.25em'}}>
                                        We offer comprehensive upholstery and reupholstery services to transform your furniture and renew your living spaces. Allow us to ehance the comfort, style and longevity of your furniture through our professional upholstery and reupholstery services.
                                    </div>
                                    <div className='col mt-3'>
                                        <Link to='services#upholstery+reupholstery' scroll={el => scrollWithOffset(el)}>
                                            <Button>Find out more</Button>
                                        </Link>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='p-2 mb-5'>
                            <Card.Title><h3 style={{fontSize:'2em'}}>Commercial, Domestic and Marine</h3></Card.Title>
                            <Card.Body>
                                <div className='row' style={{justifyContent:'space-between', display:'flex', flexDirection:'column'}}>
                                    <div className='col' style={{fontSize:'1.25em'}}>
                                        Within the Norwich and Lowestoft region, we are a leading provider of upholstery and reupholstery services who also cater to commercial, domestic and marine clients. With a wide selection of high-quality fabrics and commitment to customer satisfaction, we ensure your upholstery projects are completed to the highest standards, adding comform, durability and style to your spaces.
                                    </div>
                                    <div className='col mt-3'>
                                        <Link to='services#commercial+domestic+marine' scroll={el => scrollWithOffset(el)}>
                                            <Button>Find out more</Button>
                                        </Link>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='p-2 mb-5'>
                            <Card.Title><h3 style={{fontSize:'2em'}}>Repairs</h3></Card.Title>
                            <Card.Body>
                                <div className='row' style={{justifyContent:'space-between', display:'flex', flexDirection:'column'}}>
                                    <div className='col' style={{fontSize:'1.25em'}}>
                                        We have developed skills in diagnosing and fixing a wide range of issues, from torn fabric and broken springs to damaged padding and loose stitching. Trust us to deliver exceptional results on restoring your original pieces to their former glory.
                                    </div>
                                    <div className='col mt-3'>
                                        <Link to='services#repairs' scroll={el => scrollWithOffset(el)}>
                                            <Button>Find out more</Button>
                                        </Link>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='p-2 mb-5'>
                            <Card.Title><h3 style={{fontSize:'2em'}}>Interiors</h3></Card.Title>
                            <Card.Body>
                                <div className='row' style={{justifyContent:'space-between', display:'flex', flexDirection:'column'}}>
                                    <div className='col' style={{fontSize:'1.25em'}}>
                                        We provide top-notch interior upholstery and reupholstery services. We work with our customers to bring a fresh, inviting atmosphere into their space. We strive to truly enhance the comfort and aesthetics of your interiors.
                                    </div>
                                    <div className='col mt-3'>
                                        <Link to='services#interiors' scroll={el => scrollWithOffset(el)}>
                                            <Button>Find out more</Button>
                                        </Link>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IndexPage;