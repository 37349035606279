const CompanyInfo = ({name}) =>{
    const year = new Date().getFullYear();
    return(
        <>
            <div className="col col-lg-2 mb-4">
                <h3>{name}</h3>
                <sub>&copy; {year} - All Rights Reserved</sub>
            </div>
        </>
    )
}

export default CompanyInfo;