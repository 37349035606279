import DocumentMeta from "react-document-meta";
import ServicesPage from "../../scenes/services";

const Page = () => {
    const meta = {
        title: 'RAN Upholstery | Provided Services',
        description: 'Services provided by RAN Upholstery to the Norwich and Lowestoft areas',
        canonical: 'https://ranupholstery.co.uk/services',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'upholstery,repairs,norwich,lowestoft,suffolk,near,me,experienced,antiques,dining,sofa'
          }
        }
    }
    return(
        <>
            <DocumentMeta {...meta}>
                <ServicesPage />
            </DocumentMeta>
        </>
    )
}

export default Page;